import React, {useEffect, useState} from 'react';
import {Layout, Space} from 'antd';
import s from './PersonalAccount.module.css'
import logo from '../../assets/img/personalAccount/logo.svg'
import icon_close_circle from '../../assets/img/personalAccount/close-circle.svg'
import notify from '../../assets/img/personalAccount/info-circle.svg'
import plus from '../../assets/img/personalAccount/plus_black.svg'
import {Link, NavLink, Outlet, useNavigate} from 'react-router-dom';
import '../../components/SideMenu/index.css'
import classnames from "classnames";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    createNewTypeOfAccountThunk, GetAccountsListThunk,
    GetAccoutDetailsByAccountTypeThunk, GetLimitsListThunk,
    getUserAccountListThunk,
    LogoutThunk, setAccountsListTypesThunk, setChosenAccountTypeIdThunk,
} from "../../store/reducers/ActionCreators";
import {motion} from 'framer-motion';
import MyBtn from "../../components/ui/MyBtn/MyBtn";
import CustomModal from "../../components/ui/CustomModal/CustomModal";
import styles from "../../components/ContactUsForm/ContactUsForm.module.css";
import styles_popup from "../../styles/styles.module.css";
import SideMenu from "../../components/SideMenu/SideMenu";
import CustomModalContactContainer from "../../components/ui/CustomModalContactContainer/CustomModalContactContainer";
import CustomCheckbox from "../../components/ui/customCheckbox/CustomCheckbox";
import useOutsideClick from "../../hooks/useOutsideClick";
import MyInput from "../../components/ui/MyInput/MyInput";
import CustomCheckbox1 from "../../components/ui/CustomCheckbox1/CustomCheckbox1";
import TransparentButton from "../../components/ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from '../../assets/img/personalAccount/back.svg'
import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {calculateCurrentDate} from "../../helpers/CalculateDate";

const {Header, Sider, Content} = Layout;

const headerStyle: React.CSSProperties = {
    color: '#fff',
    height: "auto",
    backgroundColor: 'transparent',
    margin: '48px 24px 36px 264px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0'
};

const contentStyleMobile: React.CSSProperties = {
    minHeight: '100vh',
    color: '#000',
    backgroundColor: '#F6F6F6',
    padding: '0 16px 16px 16px',
};

const siderStyle: React.CSSProperties = {
    position: 'fixed',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#0E492E',
    flex: '0 0 240',
    borderRight: '1.5px solid #EEEFEF',
    zIndex: 1,
    padding: '56px 0 56px 48px',
    height: '100vh',
    overflow: "auto"
};

const validationSchema = Yup.object({
    firstName: Yup.string().test({
        message: 'First Name is required',
        test: function (value) {
            const {companyName} = this.parent;
            if (!companyName) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    lastName: Yup.string().test({
        message: 'Last Name is required',
        test: function (value) {
            const {companyName} = this.parent;
            if (!companyName) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    companyName: Yup.string().test({
        message: 'Company Name is required',
        test: function (value) {
            const {lastName, firstName} = this.parent;
            if (!lastName && !firstName) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    type: Yup.string()
});

const PersonalAccount = () => {

    const accType: string | null = useAppSelector(state => state.userReducer.user.userData.accountType)
    const accTypeList = useAppSelector(state => state.userReducer.accountTypesList)
    const chosenAccountTypeId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const clientId: string | null = useAppSelector(state => state.userReducer.user.userData.clientId)
    const firstName: string | null = useAppSelector(state => state.userReducer.user.userData.firstName)
    const lastName: string | null = useAppSelector(state => state.userReducer.user.userData.lastName)
    const companyName: string | null = useAppSelector(state => state.userReducer.user.userData.companyName)
    const isTokenValid: boolean = useAppSelector(state => state.userReducer.tokenIsValid)

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState<boolean | null>(false)
    const [isMenuShow, setIsMenuShow] = useState<boolean | null>(false)
    const [isModalForCreatingPersonalOpen, setIsModalForCreatingPersonalOpen] = useState(false)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean | null>(false)
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState<boolean | null>(false)
    const [isModalForCreatingBusinessOpen, setIsModalForCreatingBusinessOpen] = useState(false)
    const [isInactiveMoreThanThirtyMinutes, setIsInactiveMoreThanThirtyMinutes] = useState(false)
    const [isIpWasChanged, setIsIpWasChanged] = useState(false)
    const {ref, ref2, isActive, setIsActive} = useOutsideClick(false);
    const dispatch = useAppDispatch()
    const [checkboxState, setCheckboxState] = useState({
        checkbox1: false,
        checkbox2: false,
    });
    const [currentIp, setCurrentIp] = useState('');
    const sessionId = useAppSelector(store => store.userReducer.sessionId)
    const token = useAppSelector(state => state.userReducer.userToken)
    const kycVerificationStatus = useAppSelector(state => state.userReducer.user.flags.kycVerificationStatus)
    const kycFormStatus = useAppSelector(state => state.userReducer.user.flags.kycFormStatus)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)
    const isLogOutWhenUserWasInactive: boolean | undefined = useAppSelector(state => state.userReducer.user.flags.autoLogout)
    const isLogOutWhenIpWasChanged: boolean | undefined = useAppSelector(state => state.userReducer.user.flags.logoutIfIpChanges)

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    const fetchIp = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    useEffect(() => {
        let timeoutId: string | number | NodeJS.Timeout | undefined;

        const resetTimer = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                if (isLogOutWhenUserWasInactive) {
                    handleLogout();
                }
            }, 30 * 60 * 1000);
        };

        const handleLogout = () => {
            setIsInactiveMoreThanThirtyMinutes(true)
        };

        resetTimer();

        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };
    }, [isLogOutWhenUserWasInactive]);

    useEffect(() => {
        const checkIpChange = async () => {
            const newIp = await fetchIp();
            if (newIp) {
                if ((currentIp && newIp !== currentIp) && !isLogOutWhenIpWasChanged) {
                    handleLogout();
                }
                setCurrentIp(newIp);
            }
        };

        const handleLogout = () => {
            setIsIpWasChanged(true)
        };

        const intervalId = setInterval(checkIpChange, 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [currentIp, isLogOutWhenIpWasChanged]);

    useEffect(() => {
            dispatch(GetLimitsListThunk(token, clientId));
    }, []);

    const initialValues = {
        companyName: '',
        firstName: '',
        lastName: '',
        type: accTypeList[0].accountType === 'personal' ? 'business' : 'personal',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(createNewTypeOfAccountThunk(token, values))
                .then((res) => {
                    setIsSuccessPopupOpen(true)
                    dispatch(getUserAccountListThunk(token))
                        .then((res) => {
                            dispatch(setAccountsListTypesThunk(res.data))
                        })
                    dispatch(GetAccountsListThunk(token, accountId, "EUR"))
                })
                .catch((e) => {
                    setIsErrorPopupOpen(true)
                })
                .finally(() => {
                    setIsModalForCreatingPersonalOpen(false)
                    setIsModalForCreatingBusinessOpen(false)
                })
        },
    });

    const handleLogout = () => {
        dispatch(LogoutThunk(sessionId, calculateCurrentDate(), token))
            .then(() => {
                navigate("/login");
            })
    }

    useEffect(() => {
        if (kycVerificationStatus === "Approved" && kycFormStatus === "Approved") {
            setIsMenuShow(true)
        } else {
            setIsMenuShow(false)
        }
    }, [kycVerificationStatus, kycFormStatus])

    const changeAccountType = (id: number) => {
        dispatch(setChosenAccountTypeIdThunk(id))
        dispatch(GetAccoutDetailsByAccountTypeThunk(token, id))
    }

    const openAccountCreatingPopup = () => {
        if (accTypeList[0].accountType === 'personal') {
            setIsModalForCreatingBusinessOpen(true)
        } else {
            setIsModalForCreatingPersonalOpen(true)
        }
    }

    return (
        <div>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >
                <CustomModal isModalOpen={isSuccessPopupOpen} isNotify setIsModalOpen={setIsSuccessPopupOpen}>
                    <div className={styles_popup.modal_content}>
                        <div className={styles_popup.popup_subtitle}>
                            <span className={styles_popup.success}>Success!</span> Your request has been sent
                            successfully.
                        </div>
                        <div className={styles_popup.popup_button_block}>
                            <MyBtn title={'Ok'} isPersonalAccountBtn medium
                                   onClick={() => setIsSuccessPopupOpen(false)}/>
                        </div>
                    </div>
                </CustomModal>

                <CustomModal type="error" isNotify isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                    <div className={styles_popup.modal_content}>
                        <div className={styles_popup.popup_subtitle}>
                            <span className={styles_popup.error}>Failed!</span> Your request was rejected. Please try
                            again.
                        </div>
                        <div className={styles_popup.popup_button_block}>
                            <MyBtn buttonType="error" isPersonalAccountBtn title={"Ok"} onClick={() => {
                                setIsErrorPopupOpen(false)
                            }}/>
                        </div>
                    </div>
                </CustomModal>

                <CustomModal isModalOpen={isModalForCreatingPersonalOpen} type={"confirm"}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className={styles.popup_content}>
                            <img src={notify} alt=""/>
                            <div className={styles.popup_title_create_account}>
                                To add personal account please enter required data
                            </div>
                            <div className={styles.input_wrapper}>
                                <span className={styles.input_label}>First Name</span>
                                <MyInput
                                    name={'firstName'}
                                    id={'firstName'}
                                    touched={formik.touched.firstName}
                                    isError={formik.errors.firstName}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.firstName && <div className={styles.error_text}>
                                    {formik.errors.firstName}
                                </div>}
                            </div>
                            <div className={styles.input_wrapper}>
                                <span className={styles.input_label}>Last Name</span>
                                <MyInput
                                    name={'lastName'}
                                    id={'lastName'}
                                    touched={formik.touched.lastName}
                                    isError={formik.errors.lastName}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.lastName && <div className={styles.error_text}>
                                    {formik.errors.lastName}
                                </div>}
                            </div>
                            <div className={s.checkboxes}>
                                <div className={s.checkbox}>
                                    <CustomCheckbox1
                                        name={"checkbox1"}
                                        checked={checkboxState.checkbox1}
                                        onChange={(e: any) => setCheckboxState({
                                            ...checkboxState,
                                            checkbox1: e.target.checked
                                        })}
                                    />
                                    <div className={s.checkbox_text}>
                                        I agree to my personal data being processed in accordance with the
                                        <a href='/privacy_policy' className={s.link}>Privacy Policy</a>
                                    </div>
                                </div>
                                <div className={s.checkbox}>
                                    <CustomCheckbox1
                                        name={"checkbox2"}
                                        checked={checkboxState.checkbox2}
                                        onChange={(e: any) => setCheckboxState({
                                            ...checkboxState,
                                            checkbox2: e.target.checked
                                        })}
                                    />
                                    <div className={s.checkbox_text}>
                                        I agree with the
                                        {/*reverse it , because user create opposite account*/}
                                        <a href={accTypeList[0].accountType === 'personal' ? '/terms_and_conditions/business' : '/terms_and_conditions/personal'}
                                           className={s.link}>
                                            Terms of use of LuxPay
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.popup_button_wrapper}>
                                <div className={styles.button_block}>
                                    <TransparentButton
                                        title={"Back"}
                                        medium
                                        isPersonalAccountBtn buttonType={"confirm"}
                                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                        onClick={() => setIsModalForCreatingPersonalOpen(false)}/>
                                </div>
                                <div className={styles.button_block}>
                                    <MyBtn
                                        type='submit'
                                        title={"Confirm"}
                                        isPersonalAccountBtn
                                        buttonType={"confirm"}
                                        localDisable={!checkboxState.checkbox1 || !checkboxState.checkbox2}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </CustomModal>

                <CustomModal isModalOpen={isModalForCreatingBusinessOpen} type={"confirm"}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className={styles.popup_content}>
                            <img src={notify} alt=""/>
                            <div className={styles.popup_title_create_account}>
                                To add business account please enter required data
                            </div>
                            <div className={styles.input_wrapper}>
                                <span className={styles.input_label}>Company name</span>
                                <MyInput
                                    name={'companyName'}
                                    id={'companyName'}
                                    touched={formik.touched.companyName}
                                    isError={formik.errors.companyName}
                                    onChange={formik.handleChange}
                                />
                                {formik.errors.companyName && <div className={styles.error_text}>
                                    {formik.errors.companyName}
                                </div>}
                            </div>
                            <div className={s.checkboxes}>
                                <div className={s.checkbox}>
                                    <CustomCheckbox1
                                        name={"checkbox1"}
                                        checked={checkboxState.checkbox1}
                                        onChange={(e: any) => setCheckboxState({
                                            ...checkboxState,
                                            checkbox1: e.target.checked
                                        })}
                                    />
                                    <div className={s.checkbox_text}>
                                        I agree to my personal data being processed in accordance with the
                                        <a href='/privacy_policy' className={s.link}>Privacy Policy</a>
                                    </div>
                                </div>
                                <div className={s.checkbox}>
                                    <CustomCheckbox1
                                        name={"checkbox2"}
                                        checked={checkboxState.checkbox2}
                                        onChange={(e: any) => setCheckboxState({
                                            ...checkboxState,
                                            checkbox2: e.target.checked
                                        })}
                                    />
                                    <div className={s.checkbox_text}>
                                        I agree with the
                                        {/*reverse it , because user create opposite account*/}
                                        <a href={accTypeList[0].accountType === 'personal' ? '/terms_and_conditions/business' : '/terms_and_conditions/personal'}
                                           className={s.link}>
                                            Terms of use of LuxPay
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.popup_button_wrapper}>
                                <div className={styles.button_block}>
                                    <TransparentButton
                                        title={"Back"}
                                        medium
                                        isPersonalAccountBtn buttonType={"confirm"}
                                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                        onClick={() => setIsModalForCreatingBusinessOpen(false)}/>
                                </div>
                                <div className={styles.button_block}>
                                    <MyBtn
                                        title={"Confirm"}
                                        isPersonalAccountBtn
                                        buttonType={"confirm"}
                                        type={"submit"}
                                        localDisable={!checkboxState.checkbox1 || !checkboxState.checkbox2}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </CustomModal>

                <CustomModal isModalOpen={!isTokenValid} type={"error"}>
                    <div className={styles_popup.block_center}>
                        <img src={icon_close_circle} alt=""/>
                    </div>
                    <div className={styles_popup.popup_title}>
                        Oops!
                    </div>
                    <div className={styles_popup.popup_subtitle}>
                        Your session time has been expired. Please login once again.
                    </div>
                    <div className={styles_popup.popup_button_wrapper}>
                        <div className={styles_popup.popup_button_block}>
                            <MyBtn title={"Ok"}  isPersonalAccountBtn buttonType={"error"}
                                   onClick={() => handleLogout()}/>
                        </div>
                    </div>
                </CustomModal>

                <CustomModal isModalOpen={isInactiveMoreThanThirtyMinutes} type={"confirm"}>
                    <div className={styles.popup_title}>
                        Oops!
                    </div>
                    <div className={styles.popup_subtitle}>
                        You was inactive more than 30 minutes. Please login once again.
                    </div>
                    <div className={styles.popup_button_wrapper}>
                        <div className={styles.button_block}>
                            <MyBtn title={"Ok"} isPersonalAccountBtn buttonType={"confirm"}
                                   onClick={() => handleLogout()}/>
                        </div>
                    </div>
                </CustomModal>

                <CustomModal isModalOpen={isIpWasChanged} type={"confirm"}>
                    <div className={styles.popup_title}>
                        Oops!
                    </div>
                    <div className={styles.popup_subtitle}>
                        Your IP was changed. Please login once again.
                    </div>
                    <div className={styles.popup_button_wrapper}>
                        <div className={styles.button_block}>
                            <MyBtn title={"Ok"} isPersonalAccountBtn buttonType={"confirm"}
                                   onClick={() => handleLogout()}/>
                        </div>
                    </div>
                </CustomModal>

                <CustomModalContactContainer setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}/>

                <div className={s.wrapper_desktop}>
                    <div className={s.test_grid}>
                        <div className={s.side_menu}>
                            <div className={s.fixed}>
                                <SideMenu isMenuShow={isMenuShow} handleLogout={handleLogout}/>
                            </div>
                        </div>
                        <div className={s.main_content}>
                            <div className={s.header}>
                                <div className={s.left}>
                                    <div className={s.icons_block}>
                                        <div className={s.round} onClick={() => setIsModalOpen(true)}>
                                            <div className={s.mail}></div>
                                        </div>
                                        {/* <div className={s.round}>
                                            <img className={s.icon_point} src={icon_point} alt=""/>
                                            <div className={s.chat}></div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className={s.right}>
                                    <div className={isActive ? s.userRoundActive : s.userRound}
                                         onClick={() => {
                                             setIsActive(!isActive)
                                         }}
                                         ref={ref2}
                                    >
                                        <div className={s.always_show}>
                                            <div className={s.user_typography}>
                                                <div><p className={s.name}>{firstName} {lastName} {companyName}</p>
                                                </div>
                                                <div><p className={s.id}>ID: {clientId}</p></div>
                                            </div>
                                            <div className={s.user}></div>
                                        </div>

                                        <div
                                            ref={ref}
                                            className={isActive ? s.openDetails : s.openDetailsHidden}
                                        >
                                            <div>
                                                {
                                                    accTypeList.map((acc) =>
                                                        <div className={s.checkbox_block} key={acc.id}>
                                                            {!acc.activeStatus && <span className={s.inactive_notify}>Account is inactive</span>}
                                                            <label className={s.flex_label} onClick={() => {
                                                                if (acc.activeStatus) {
                                                                    changeAccountType(acc.id)
                                                                }
                                                            }}>
                                                                <CustomCheckbox
                                                                    handleChange={() => {
                                                                    }}
                                                                    id={acc.accountType}
                                                                    name={acc.accountType}
                                                                    isChecked={chosenAccountTypeId === acc.id}

                                                                />
                                                                <span className={classnames(s.account_type_label, !acc.activeStatus && s.inactive_account)}>
                                                                        {acc.accountType === 'personal' ? 'Personal account' : 'Business account'}
                                                                    </span>
                                                            </label>
                                                        </div>
                                                    )
                                                }
                                                {accTypeList.length === 1 &&
                                                    <div className={s.checkbox_block}>
                                                        <label className={s.flex_label}
                                                               onClick={() => openAccountCreatingPopup()}>
                                                            <img src={plus} alt="" className={s.icon_plus}/>
                                                            <span className={s.account_type_label}>
                                                                    {
                                                                        accTypeList[0].accountType === 'personal'
                                                                            ? 'Add business account'
                                                                            : 'Add personal account'
                                                                    }
                                                                </span>
                                                        </label>
                                                    </div>
                                                }
                                            </div>
                                            <div className={s.logout_block}>
                                                <div className={s.logout_row} onClick={() => handleLogout()}>
                                                    <div className={s.img_logout}></div>
                                                    <div className={s.text}>Logout</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <main className={s.main_wrapper}>
                                <Outlet/>
                            </main>
                        </div>
                    </div>
                </div>
                {isMobile &&
                    <div className={s.wrapper_mobile}>
                        <Space direction="vertical" style={{width: '100%', position: "relative", background: '#F6F9FF'}}
                               size={[0, 0]}>
                            <Layout style={{background: '#F6F9FF'}}>
                                <Sider className={s.sider} style={siderStyle} width={240}>
                                    <div>
                                        <Link to="/">
                                            <img src={logo} alt="" className={s.logo}/>
                                        </Link>
                                    </div>
                                    {
                                        isMenuShow
                                            ? <></>
                                            : <div>
                                                <div className={s.links_title}>services</div>
                                                <NavLink to={`/personal_account/accounts`}
                                                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                                    <div className="img_block">
                                                        <div className="img_accounts"></div>
                                                    </div>
                                                    <div className="text_block">
                                                        Accounts
                                                    </div>
                                                </NavLink>
                                                <NavLink to={`/personal_account/transfers`}
                                                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                                    <div className="img_block">
                                                        <div className="img_transfers"></div>
                                                    </div>
                                                    <div className="text_block">
                                                        Transfers
                                                    </div>
                                                </NavLink>
                                                <NavLink to={`/personal_account/templates`}
                                                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                                    <div className="img_block">
                                                        <div className="img_templates"></div>
                                                    </div>
                                                    <div className="text_block">
                                                        Templates
                                                    </div>
                                                </NavLink>
                                                <NavLink to={`/personal_account/currency`}
                                                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                                    <div className="img_block">
                                                        <div className="img_currency"></div>
                                                    </div>
                                                    <div className="text_block">
                                                        Currency
                                                    </div>
                                                </NavLink>
                                            </div>

                                    }

                                    <div className={classnames(s.links_title, s.services_block)}>Account</div>

                                    <NavLink to={`/personal_account/security`}
                                             className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                        <div className="img_block">
                                            <div className="img_security"></div>
                                        </div>
                                        <div className="text_block">
                                            Security
                                        </div>
                                    </NavLink>
                                    <NavLink to={`/personal_account/${accType}/settings`}
                                             className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                        <div className="img_block">
                                            <div className="img_settings"></div>
                                        </div>
                                        <div className="text_block">
                                            Settings
                                        </div>
                                    </NavLink>

                                    <div className={classnames('inactive')} onClick={() => handleLogout()}>
                                        <div className="img_block">
                                            <div className="img_logout"></div>
                                        </div>
                                        <div className={s.logout}>Logout</div>
                                    </div>
                                </Sider>
                                <Layout>
                                    {<div className={s.header_wrapper}>
                                        <Header style={headerStyle}>
                                            <div className={s.left}>
                                                <div className={s.icons_block}>
                                                    <div className={s.round} onClick={() => setIsModalOpen(true)}>
                                                        <div className={s.mail}></div>
                                                    </div>
                                                    {/*<div className={s.round}>*/}
                                                    {/*    <img className={s.icon_point} src={icon_point} alt=""/>*/}
                                                    {/*    <div className={s.chat}></div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                            <div className={s.right}>
                                                <div className={s.user_round}>
                                                    {firstName?.slice(0, 1)}{lastName?.slice(0, 1)}{companyName?.slice(0, 1)}
                                                </div>
                                            </div>
                                        </Header>
                                    </div>}
                                    <Content style={contentStyleMobile}>
                                        <Outlet/>
                                    </Content>
                                </Layout>
                            </Layout>
                        </Space>
                    </div>
                }
            </motion.div>
        </div>
    );
};

export default PersonalAccount;
