import React, {useState} from 'react';
import s from './CreateTemplate.module.css'
import {Dropdown, MenuProps, Popover, Space} from "antd";
import arrow from "../../../../assets/img/personalAccount/arrow.svg";
import info from "../../../../assets/img/personalAccount/information.svg";
import MyInput from "../../../../components/ui/MyInput/MyInput";
import {useParams} from "react-router-dom";
import ToLuxpayTransactionTemplate
    from "../../../../components/TransactionTemplates/ToLuxPayTransactionTemplate/ToLuxPayTransactionTemplate";
import BankTransactionTemplate
    from "../../../../components/TransactionTemplates/BankTransactionTemplate/BankTransactionTemplate";
import VerifyWithdrawalHelper from "../../../../helpers/VerifyWithdrawalHelper";

interface CreateTemplateProps {
    typeForm?: string,
    setShowForm?: any;
}

const templateType: MenuProps['items'] = [
    {
        label: 'To accounts in Lithuania and foreign banks',
        key: 'To accounts in Lithuania and foreign banks',
    },
    {
        label: 'Transfer to LuxPay User',
        key: 'Transfer to LuxPay User',
    },
];

export const menuStyle = {
    border: '1px solid #E7E7E7',
    borderRadius: 4,
};

export const contentStyle = {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#282828'
};

const CreateTemplate = (props: CreateTemplateProps) => {

    const [purpose, setPurpose] = useState('To accounts in Lithuania and foreign banks')
    const {id, type } = useParams()

    const [isButtonDisable, setIsButtonDisabled] = useState(true)
    const [code, setCode] = useState("")
    const [withdrawalStatus, setWithdrawalStatus] = useState("")

    const handleItemClick: MenuProps['onClick'] = (e) => {
        setPurpose(e.key);
    };

    const [templateName, setTemplateName] = useState<string>('')
    const templateNameError = 'max 100 symbols'

    return (
        <div className={s.templateWrapper}>

            {
                id === "create"
                ? <div className={s.detailsWrapper}>
                        <p className={s.title}>New Template</p>

                        <div className={s.inputsWrapper}>
                            <div className={s.detailsWrapperItem}>
                                <div className={s.row}>
                                    <p className={s.detailsWrapperItemName}>template Name</p>
                                    <Popover
                                        content={"In order to save this transfer as a template, please enter the template name."}
                                        color={'#EEEFEF'}
                                        placement={"bottom"}
                                        overlayInnerStyle={{
                                            fontFamily: 'Manrope',
                                            width: '280px',
                                            padding: '13px 16px',
                                            cursor: 'pointer',
                                            backgroundColor: '#E7F0EC'
                                        }}
                                    >
                                        <img src={info} alt=""/>
                                    </Popover>
                                </div>
                                <MyInput
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.currentTarget.value)}
                                    width={'100%'}
                                    isError={(templateName.length > 100 || templateName.length <= 0)}
                                />
                                {templateName.length > 100 && <div className={s.error_label}>{templateNameError}</div>}
                                {templateName.length <= 0 && <div className={s.error_label}>Required field</div>}
                            </div>

                            <div className={s.detailsWrapperItem}>
                                <p className={s.detailsWrapperItemName}>Transfer type</p>
                                <Dropdown menu={{items: templateType, onClick: handleItemClick}} trigger={['click']}
                                          dropdownRender={(menu: React.ReactNode) => (
                                              <div style={contentStyle}>
                                                  {React.cloneElement(menu as React.ReactElement, {style: menuStyle})}
                                              </div>
                                          )}>
                                    <Space style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        height: 52,
                                        color: '#2A2E37',
                                        border: '1px solid #E7E7E7',
                                        borderColor: '#E7E7E7',
                                        borderRadius: 4,
                                        fontFamily: 'Manrope',
                                        fontWeight: 600,
                                        fontSize: 14,
                                        lineHeight: '18px',
                                        paddingLeft: '16px',
                                        paddingRight: '16px',
                                        cursor: 'pointer'
                                    }}>
                                        <p className={s.textWrapper}>{purpose}</p>
                                        <img alt={'dropdownArrow'} className={s.arrow_img} src={arrow}/>
                                    </Space>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                :<>
                    <VerifyWithdrawalHelper
                        setWithdrawalStatus={setWithdrawalStatus}
                        setIsButtonDisabled={setIsButtonDisabled}
                        setCode={setCode}
                    />
                </>
            }

            <div className={s.payer_details}>
                <p className={s.title}>Payer Details</p>
                {
                    purpose === "To accounts in Lithuania and foreign banks"
                        ? <BankTransactionTemplate
                            id={id}
                            templateName={templateName}
                            setPurpose={setPurpose}
                            isButtonDisable={isButtonDisable}
                            withdrawalStatus={withdrawalStatus}
                            code={code}
                        />
                        : <ToLuxpayTransactionTemplate
                            id={id}
                            templateName={templateName}
                            setPurpose={setPurpose}
                            isButtonDisable={isButtonDisable}
                            withdrawalStatus={withdrawalStatus}
                            code={code}
                        />
                }
            </div>

        </div>

    )
}

export default CreateTemplate;
