import React, {Dispatch, SetStateAction, useState} from 'react';
import s from "./ChooseAccountTypeStep.module.css";
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import {motion} from "framer-motion";
import CustomCheckbox from "../../../components/ui/customCheckbox/CustomCheckbox";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {
    GetAccoutDetailsByAccountTypeThunk, getLoginHistoryThunk, GetTransactionsListThunk,
    LogoutThunk,
    setChosenAccountTypeIdThunk
} from "../../../store/reducers/ActionCreators";
import {useNavigate} from "react-router-dom";
import info from "../../../assets/img/personalAccount/information.svg";
import {Popover} from "antd";
import classnames from "classnames";
import CustomModal from "../../../components/ui/CustomModal/CustomModal";
import styles from "../../../styles/styles.module.css"
import error_img from "../../../assets/img/personalAccount/close-circle.svg"
import {calculateCurrentDate} from "../../../helpers/CalculateDate";
import Steps from '../../../components/ui/Steps/Steps';

type AccountType = {
    id: number
    accountType: string
    owner: string,
    activeStatus: boolean
}

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    AccountsList: AccountType[]
}

const ChooseAccountTypeStep = (props: PropsType) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const userToken = useAppSelector(store => store.userReducer.userToken)
    const sessionId = useAppSelector(store => store.userReducer.sessionId)
    const [accountType, setAccountType] = useState("")
    const [chosenAccountId, setChosenAccountId] = useState<any>()
    const [disabled, setDisabled] = useState<boolean>(false)

    const sendChosenAccount = () => {
        setDisabled(true)
        dispatch(setChosenAccountTypeIdThunk(chosenAccountId))
        dispatch(GetAccoutDetailsByAccountTypeThunk(userToken, chosenAccountId))
            .then((res) => {
                dispatch(GetTransactionsListThunk(userToken, chosenAccountId))
                dispatch(getLoginHistoryThunk(userToken, calculateCurrentDate()))
                if (res.data.flags.kycStatus !== "Approved") {
                    setDisabled(false)
                    navigate("/personal_account/personal/settings");
                } else {
                    navigate('/personal_account/accounts')
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const isBothAccountInactive = (accounts: AccountType[]): boolean => {
        return accounts.every((acc: AccountType, index, array) => {
            return !acc.activeStatus
        })
    }

    const handleLogout = () => {
        dispatch(LogoutThunk(sessionId, calculateCurrentDate(), userToken))
        props.setCurrent(0)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>

                <CustomModal type={'error'} isModalOpen={isBothAccountInactive(props?.AccountsList)}>
                    <div className={classnames(styles.modal_content, s.modal_content)}>
                        <img className={s.error_img} src={error_img} alt=""/>
                        <span className={classnames(s.error_title)}>
                            Your personal and business accounts are inactive.
                        </span>
                        <span className={styles.popup_subtitle}>
                             Please contact support if you have any questions via&nbsp;
                            <a className={s.mail_link} href="mailto:support@luxpay.lt">support@luxpay.lt</a>.
                        </span>
                        <div className={styles.popup_button_block}>
                            <MyBtn title={"Ok"}
                                   buttonType="error"
                                   isPersonalAccountBtn
                                   large
                                   onClick={() => {handleLogout()}}
                            />
                        </div>
                    </div>
                </CustomModal>

                <div className={s.form_wrapper}>
                    <div className={s.title}>
                        Client account
                    </div>

                    <div className={s.subtitle_block}>
                        <div className={s.subtitle}>
                            Please select the account you want to log in to.
                        </div>
                    </div>

                    <div className={s.account_list_block}>
                        {
                            props?.AccountsList?.map((item, index) =>
                                <>
                                    {
                                        !item.activeStatus &&
                                        <div className={s.inactive_notification_block}>
                                            <span className={s.inactive_error_text}>Your account is inactive</span>
                                            <Popover content={
                                                <span>
                                                    Please contact support if you have any questions via&nbsp;
                                                    <a href="mailto:support@luxpay.lt" className={s.mail_link}>support@luxpay.lt</a>.
                                                </span>
                                            }
                                                     color={'#EEEFEF'}
                                                     overlayInnerStyle={{
                                                         fontFamily: 'Manrope',
                                                         width: '280px',
                                                         height: 'fit-content',
                                                         padding: '13px 16px',
                                                         margin: '0',
                                                         cursor: 'pointer',
                                                         backgroundColor: '#E7F0EC'
                                                     }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                    }
                                    <div key={item.id} className={classnames(chosenAccountId === item.id ? s.chosen_account_wrapper : s.account_wrapper,!item.activeStatus && s.account_inactive)}>
                                        <div className={classnames(s.acc_type,!item.activeStatus && s.acc_type_inactive)}>
                                            {item.accountType === "personal" ? "Personal account" : "Business account"}
                                        </div>
                                        <label className={s.flex_label} onClick={() => {setChosenAccountId(item.id)}}>
                                            <CustomCheckbox
                                                handleChange={() => {item.activeStatus && setAccountType(item.accountType)}}
                                                id={item.accountType}
                                                name={item.accountType}
                                                isChecked={item.accountType === accountType}
                                            />
                                            <span className={classnames(s.account_type_label, item.accountType === accountType && s.chosen_account_type_label)}>{item.owner}</span>
                                        </label>
                                    </div>
                                </>
                            )
                        }
                    </div>

                    <div className={s.btn_block}>
                        <div className={s.btn_wrapper}>
                            <MyBtn title={"Sign In"}
                                   localDisable={!chosenAccountId || disabled}
                                   large
                                   isPersonalAccountBtn
                                   onClick={() => { sendChosenAccount()}}
                            />
                        </div>
                    </div>
                    <div className={s.back}>
                        Back to <span className={s.link} onClick={() => props.setCurrent(0)}>Sign In</span>
                    </div>

                    <div className={s.steps}><Steps quantity={3} activeNum={2} /></div>
                </div>

            </div>

        </motion.div>
    );
};

export default ChooseAccountTypeStep;
