 import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {motion} from "framer-motion";
import s from "../CurrencyExchange.module.css";
import {Formik} from "formik";
import MyInput from "../../../../../components/ui/MyInput/MyInput";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import CustomDropdownForAccounts
    from "../../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import * as yup from "yup";
import {
    setCurrencyExchangeAmount,
    setCurrencyExchangeFrom,
    setCurrencyExchangeResult, setCurrencyExchangeTo
} from "../../../../../store/reducers/UserSlice";
import useDebounce from "../../../../../hooks/useDebounce";
import {useSelector} from "react-redux";
import {selectAccountsByFilter} from "../../../../../store/reducers/TransactionsSlice/selector";
import {
    sendCurrencyExchangeAmount,
    sendCurrencyExchangeAmountReverse
} from "../../../../../store/reducers/ActionCreators";
 import {SetCurrencyByAccount} from "./ExchangeCurrencyHelper";

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
    setExchangeData: Dispatch<SetStateAction<any>>
}

const validationSchema = yup.object({
    accNumberFrom: yup.string().required("Account is required"),
    accNumberTo: yup.string().required("Account is required"),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .test({
            message: 'One of the fields is required',
            test: function (value) {
                const {toAmount} = this.parent;
                if (!toAmount) return value !== undefined && value !== null && value !== '';
                return true
            },
        })
        .test('only-one-greater-than-ten', "min amount 10", function (value: any) {
            const { toAmount } = this.parent;
            const amountValue = parseFloat(toAmount);
            const toAmountValue = parseFloat(value);
            const amountValid = !isNaN(amountValue) && amountValue >= 10;
            const toAmountValid = !isNaN(toAmountValue) && toAmountValue >= 10;

            return (amountValid && !toAmountValid) || (!amountValid && toAmountValid);
        }),
    toAmount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
        .test({
            message: 'One of the fields is required',
            test: function (value) {
                const {amount} = this.parent;
                if (!amount) return value !== undefined && value !== null && value !== '';
                return true
            },
        })
        .test('only-one-greater-than-ten', "min amount 10", function (value: any) {
            const { amount } = this.parent;
            const amountValue = parseFloat(amount);
            const toAmountValue = parseFloat(value);
            const amountValid = !isNaN(amountValue) && amountValue >= 10;
            const toAmountValid = !isNaN(toAmountValue) && toAmountValue >= 10;

            return (amountValid && !toAmountValid) || (!amountValid && toAmountValid);
        }),
})

const ExchangeStepOne = (props: PropsType) => {

    const accountsForSelect = useSelector(selectAccountsByFilter)
    const currencyExchange = useAppSelector(state => state.userReducer.currencyExchange)
    const currencyExchangeResult = useAppSelector(state => state.userReducer.currencyExchangeResult)
    const dispatch = useAppDispatch()
    const [accountNumberFrom, setAccountNumberFrom] = useState("")
    const [accountNumberTo, setAccountNumberTo] = useState("")

    const initialValues = {
        accNumberFrom: '',
        accNumberTo: '',
        amount: '',
        accNumberFromCurrency: '',
        toAmount: '',
        accNumberToCurrency: '',
        type: "exchange"
    }

    const handleRequest = (debouncedValue: any) => {
        if (debouncedValue.amount !== '') {
            const tempData = {
                from: currencyExchange.from,
                to: currencyExchange.to,
                amount: debouncedValue.amount
            }
            dispatch(sendCurrencyExchangeAmount(tempData))
            return
        }
        if (debouncedValue.toAmount !== '') {
            const tempData = {
                from: currencyExchange.from,
                to: currencyExchange.to,
                amount: debouncedValue.toAmount
            }
            dispatch(sendCurrencyExchangeAmountReverse(tempData))
            return
        }
    }

    const debouncedValue = useDebounce(currencyExchange, 1500);

    useEffect(() => {
        handleRequest(debouncedValue)
    }, [debouncedValue])

    useEffect(() => {

        return () => {
            dispatch(setCurrencyExchangeAmount(''))
            dispatch(setCurrencyExchangeResult(''))
            dispatch(setCurrencyExchangeFrom(''))
            dispatch(setCurrencyExchangeTo(''))
        }

    }, [])

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        const tempData = {
                            ...values,
                            result: currencyExchangeResult,
                            hiddenAccountNumberFrom: accountNumberFrom,
                            hiddenAccountNumberTo: accountNumberTo
                        }
                        props.setExchangeData([tempData])
                        props.setCurrent(1)
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (

                        <form onSubmit={handleSubmit} className={s.form}>
                            <div className={s.flex}>
                                <div className={s.dropdown_block}>
                                    <div className={s.input_label}>
                                        from account
                                    </div>
                                    <CustomDropdownForAccounts
                                        id={'accNumberFrom'}
                                        name={'accNumberFrom'}
                                        otherFieldName={'accNumberTo'}
                                        items={accountsForSelect}
                                        setAccountNumber={setAccountNumberFrom}
                                    />
                                    {errors.accNumberFrom && touched.accNumberFrom &&
                                        <div className={s.error_message_account}>{errors.accNumberFrom}</div>}
                                </div>
                                <div className={s.dropdown_block}>
                                    <div className={s.input_label}>
                                        to account
                                    </div>
                                    <CustomDropdownForAccounts
                                        id={'accNumberTo'}
                                        name={'accNumberTo'}
                                        otherFieldName={'accNumberFrom'}
                                        items={accountsForSelect}
                                        setAccountNumber={setAccountNumberTo}
                                    />
                                    {errors.accNumberTo && touched.accNumberTo &&
                                        <div className={s.error_message_account}>{errors.accNumberTo}</div>}
                                </div>
                            </div>
                            <div className={s.flex}>
                                <div className={s.amount_block}>
                                    <div className={s.amount_input}>
                                        <div className={s.input_label}>
                                            For
                                        </div>
                                        <MyInput
                                            disabled={!!values.toAmount}
                                            id={'amount'}
                                            name={'amount'}
                                            value={(currencyExchangeResult && !values.amount) ? currencyExchangeResult : values.amount}
                                            onChange={handleChange}
                                        />
                                      {errors.amount && touched.amount &&
                                          <div className={s.error_message}>{errors.amount}</div>}
                                    </div>
                                    <div className={s.currency_input_block}>
                                        <div className={s.input_label}>
                                            Currency
                                        </div>
                                        <MyInput
                                            id={'forAmountCurrency'}
                                            name={'forAmountCurrency'}
                                            value={values.accNumberFromCurrency}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className={s.amount_block}>
                                    <div className={s.amount_input}>
                                        <div className={s.input_label}>
                                            Get
                                        </div>
                                        <MyInput
                                            disabled={!!values.amount}
                                            id='toAmount'
                                            name='toAmount'
                                            value={(currencyExchangeResult && !values.toAmount) ? currencyExchangeResult : values.toAmount}
                                            onChange={handleChange}
                                        />
                                      {errors.toAmount && touched.toAmount &&
                                          <div className={s.error_message}>{errors.toAmount}</div>}
                                    </div>
                                    <div className={s.currency_input_block}>
                                        <div className={s.input_label}>
                                            Currency
                                        </div>
                                        <MyInput
                                            id={'getAmountCurrency'}
                                            name={'getAmountCurrency'}
                                            value={values.accNumberToCurrency}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={s.button_wrapper}>
                                <div className={s.button_block}>
                                    <MyBtn
                                        title={'Continue'}
                                        type={'submit'}
                                        isPersonalAccountBtn
                                        large
                                        onClick={() => console.log(values)}
                                    />
                                </div>
                            </div>
                            <SetCurrencyByAccount
                                accountsForSelect={accountsForSelect}
                                selectedAccountCurrencyGet={currencyExchange.to}
                                selectedAccountCurrencyFor={currencyExchange.from}
                                accountNumberFrom={accountNumberFrom}
                                accountNumberTo={accountNumberTo}
                            />

                            {/*<ReverseAccountsHelper/>*/}

                        </form>
                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default ExchangeStepOne;
