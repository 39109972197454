import {AppDispatch} from "../store";
import {Api, ApiBicChecker} from "../../api/Api";
import {
    accountTypeItem,
    setAccountsLimit,
    setAccountsList,
    setCurrencyExchangeResult,
    setCurrencyExchangeResultCheck,
    setDisabled,
    setIsLoading,
    setNewEmail,
    setTokenStatus,
    userSlice
} from "./UserSlice";
import {setTemplateList, setTransactionsData, transactionsSlice} from "./TransactionsSlice/TransactionsSlice";
import {
    EmailVereficationDataType
} from "../../components/Autentications/EmailAutentication/EmailAutenticationLogin/EmailAutenticationLogin";
import {IAccounts, IContactData, IloginData, ITransacton} from "../../types/interfaces";
import {IExchangeBody} from "../../pages/PersonalAccount/Currency/CurrencyExchange/ExchangeStepTwo/ExchangeStepTwo";

export type LoginDataType = {
    email: string, password: string,
}

export type LogoutDataType = {
    sessionId: string, sessionEnd: string,
}

export type RegistrationDataType = {
    type: string,
    firstName?: string,
    lastName?: string,
    companyName?: string,
    email: string,
    password: string,
}

export const getAdditionalsThunk = (data: LoginDataType) => async () => {
    return await Api.login(data)
}

export const RegistrationThunk = (data: RegistrationDataType) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    await dispatch(userSlice.actions.setUserEmail(data.email))
    await Api.registration(data)
    await dispatch(userSlice.actions.setRegistrationWasSuccess(true))
    dispatch(setDisabled(false))
}

export const LogoutThunk = (sessionId: number | null, sessionEnd: string, token: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setDisabled(true))
        dispatch(userSlice.actions.signOut())
        dispatch(transactionsSlice.actions.clearTransactionSlice())
        dispatch(setDisabled(false))
        await Api.logout(sessionId, sessionEnd, token)
    } catch (e: any) {
        console.error(e)
        dispatch(setDisabled(false))
    }
}

export const loginVereficationThunk = (data: EmailVereficationDataType) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    await Api.emailVerefication(data)
    dispatch(setDisabled(false))
}

export const sendPersonalKycThunk = (token: string, data: any) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    return await Api.sendPersonalKyc(token, data)
}

export const getKycListThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.getKycList(token)
        dispatch(userSlice.actions.setKycList(response.data))
        return response
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const deleteKycThunk = (token: string, id: number) => async (dispatch: AppDispatch) => {
    try {
        await Api.deleteKyc(token, id);
        dispatch(getKycListThunk(token))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const editKycThunk = (token: string, data: any) => async (dispatch: AppDispatch) => {
    return await Api.updateKyc(token, data)
}

export const get2FaThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        return await Api.get2Fa(token)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const send2FaThunk = (token: string, data: any) => async (dispatch: AppDispatch) => {
    return await Api.send2FaCode(token, data)
}

export const GetAccountsListThunk = (token: string, id: number | null, currency: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setIsLoading(true))
        const response = await Api.getAccountsList(token, id, currency)

        dispatch(setAccountsList(response.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    } finally {
        dispatch(setIsLoading(false))
    }
}

export const CreateAccountsThunk = (token: string, accounts: IAccounts, accountId: number | null) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setDisabled(true))
        return await Api.createAccounts(token, accounts, accountId)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    } finally {
        dispatch(setDisabled(false))
    }
}

export const GetTransactionsListThunk = (token: string, accountId: number | null) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.getTransactionsList(token, accountId)
        dispatch(setTransactionsData(response.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const SetNew2FaStatus = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(userSlice.actions.setIs2Fa(true))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const finalLoginThunk = (data: IloginData) => async (dispatch: AppDispatch) => {
    localStorage.clear()
    dispatch(setDisabled(true))
    const response = await Api.finalLogin(data)
    return response
}

export const getUserAccountListThunk = (token: string) => async (dispatch: AppDispatch) => {
    const response = await Api.getUserAccountList(token)
    return response
}

export const SendTransferThunk = (token: string, dataForTransaction: any, accountId: number | null) => async (dispatch: AppDispatch) => {
    const res = await Api.sendTransfer(token, dataForTransaction)
    dispatch(GetTransactionsListThunk(token, accountId))
    return res
}

export const createTemplate = (token: string, dataForTemplate: ITransacton) => async (dispatch: AppDispatch) => {
    try {
        return await Api.createTemplate(token, dataForTemplate)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const getTemplateListThunk = (token: string, accountId: number | null) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.getTemplateList(token, accountId)
        dispatch(setTemplateList(res.data))
        return res
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }

}

export const getTemplateByIdThunk = (token: string, id: string | number | undefined, accountId: number | null) => async (dispatch: AppDispatch) => {
    try {
        return await Api.getTemplateById(token, id, accountId)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const deleteTemplateThunk = (token: string, id: number, accountId: number | null) => async (dispatch: AppDispatch) => {
    const res = await Api.deleteTemplate(token, id, accountId)
    dispatch(getTemplateListThunk(token, accountId))
    return res
}

export const sendCurrencyExchangeAmount = (body: IExchangeBody) => async (dispatch: AppDispatch) => {
    const res = await Api.sendExchange(body)
    try {
        dispatch(setCurrencyExchangeResult(res.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }

    return res
}

export const sendCurrencyExchangeAmountCheck = (body: IExchangeBody) => async (dispatch: AppDispatch) => {
    const res = await Api.sendExchange(body)
    try {
        await dispatch(setCurrencyExchangeResultCheck(res.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }

    return res
}

export const sendCurrencyExchangeAmountReverse = (body: IExchangeBody) => async (dispatch: AppDispatch) => {
    const res = await Api.sendExchangeRevert(body)
    try {
        await dispatch(setCurrencyExchangeResult(res.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }

    return res
}

export const sendCurrencyExchangeAmountReverseCheck = (body: IExchangeBody) => async (dispatch: AppDispatch) => {
    const res = await Api.sendExchangeRevert(body)
    try {
        await dispatch(setCurrencyExchangeResultCheck(res.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }

    return res
}

export const getCardInfo = (token: string) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.getCardInfo(token)
        await dispatch(userSlice.actions.setCardInfo(res.data.card))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const resetPasswordThunk = (data: any) => async () => {
    return await Api.resetPassword(data)
}

export const createNewPasswordThunk = (token: string, data: any) => async (dispatch: AppDispatch) => {
    return await Api.createNewPassword(token, data)
}

export const sendDataForNewPasswordThunk = (email: string) => async () => {
    return await Api.sendDataForNewPassword(email)
}

export const setNewPasswordThunk = (link: string, password: string, passwordConfirmation: string) => async () => {
    return await Api.setNewPassword(link, password, passwordConfirmation)
}

export const getTopUpInfo = (token: string, data: any) => () => {
    return Api.getTopUpInfo(token, data)
}

export const addNewPhoneThunk = (token: string, phone: any) => async (dispatch: AppDispatch) => {
    const res = await Api.addNewPhone(token, phone)
    return res
}

export const getCodeForVerifyPhoneThunk = (token: string, phoneId: any) => async (dispatch: AppDispatch) => {
    try {
        return await Api.getCodeForVerifyPhone(token, phoneId)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const sendCodeThunk = (token: string, phoneId: any, code: string) => async (dispatch: AppDispatch) => {
    return await Api.sendVerifyCode(token, phoneId, code)

}

export const setSmsCodeThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        await Api.setSmsCode(token);
        dispatch(userSlice.actions.setIsSmsCode(true))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const sendContactFormData = (data: IContactData) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    return await Api.sendContactForm(data)
}

export const sendCodeToNewEmailThunk = (email: string, token: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setDisabled(true))
        return await Api.sendCodeToNewEmail(token, email)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const sendConfirmationCodeFromOldEmailThunk = (codeFromCurrentEmail: string, token: string) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    return await Api.sendConfirmationCodeFromOldEmail(codeFromCurrentEmail, token)
}

export const verifyAndChangeNewEmailThunk = (newEmail: string, codeFromNewEmail: string, codeFromCurrentEmail: string, token: string) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    return await Api.sendConfirmationCodeFromNewEmail(newEmail, codeFromNewEmail, codeFromCurrentEmail, token)
}

export const getNewEmailThunk = (token: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setDisabled(true))
        const res = await Api.getNewEmail(token)
        dispatch(setNewEmail(res.data.email))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const setTokenState = (isValid: boolean) => async (dispatch: AppDispatch) => {
    dispatch(setTokenStatus(isValid))
}

export const disableSmsAutentication = (token: string) => async (dispatch: AppDispatch) => {
    try {
        await Api.disableSmsCode(token);
        dispatch(userSlice.actions.setIsSmsCode(false))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const disable2faAutentication = (token: string) => async (dispatch: AppDispatch) => {
    try {
        await Api.disable2faCode(token);
        dispatch(userSlice.actions.setIs2faCode(false))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const setTokenThunk = (token: string) => async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setToken(token))
}

export const setSessionIdThunk = (sessionId: number) => async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setSessionId(sessionId))
}

export const GetAccoutDetailsByAccountTypeThunk = (token: string, id: number | null) => async (dispatch: AppDispatch) => {
    try {
        const accountDetails = await Api.getAccoutDetailsByAccountType(token, id);
        dispatch(userSlice.actions.setUserData(accountDetails.data))
        const accountsList = await Api.getAccountsList(token, id, "EUR");
        dispatch(userSlice.actions.setAccountsList(accountsList.data))
        const templateList = await Api.getTemplateList(token, id);
        dispatch(transactionsSlice.actions.setTemplateList(templateList.data))
        return accountDetails
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const setAccountsListTypesThunk = (accountsTypesList: accountTypeItem[]) => async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setAccountTypesList(accountsTypesList))
}

export const setChosenAccountTypeIdThunk = (id: unknown) => async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setChosenAccountTypeId(id))
}

export const checkAvaliableAccountsForSendingThunk = (selectionType: number, token: string, data: any) => async (dispatch: AppDispatch) => {
    try {
        return await Api.getAvaliableAccountsForSending(token, selectionType, data)
    } catch (e) {
        return e
    }
}

export const createNewTypeOfAccountThunk = (token: string, data: any) => async (dispatch: AppDispatch) => {
    return await Api.createNewTypeOfAccountThunk(token, data)
}

export const startVerificationProcessThunk = (token: string, accountId: number | null) => async (dispatch: AppDispatch) => {
    dispatch(setDisabled(true))
    return await Api.sendDataToStartVerification(token, accountId)
}

export const setIsLogOutWhenUserWasInactiveThunk = (token: string, payload: boolean, id: number | null) => async (dispatch: AppDispatch) => {
    try {
        await Api.setAutoLogoutSetting(token, payload)
        const accountDetails = await Api.getAccoutDetailsByAccountType(token, id);
        dispatch(userSlice.actions.setUserData(accountDetails.data))
    } catch (e) {
        return e
    }
}

export const setIsLogOutWhenIpWasChangedThunk = (token: string, payload: boolean, id: number | null) => async (dispatch: AppDispatch) => {
    try {
        await Api.setLogoutIfIPChanged(token, payload)
        const accountDetails = await Api.getAccoutDetailsByAccountType(token, id);
        dispatch(userSlice.actions.setUserData(accountDetails.data))
    } catch (e) {
        return e
    }
}

export const sendEmailOnLoginHandleChangeThunk = (token: string, payload: boolean, id: number | null) => async (dispatch: AppDispatch) => {
    try {
        await Api.sendEmailOnLogin(token, payload)
        const accountDetails = await Api.getAccoutDetailsByAccountType(token, id);
        dispatch(userSlice.actions.setUserData(accountDetails.data))
    } catch (e) {
        return e
    }
}

export const setSaveLoginHistoryThunk = (token: string, payload: boolean, id: number | null) => async (dispatch: AppDispatch) => {
    try {
        await Api.setSaveLoginHistory(token, payload)
        const accountDetails = await Api.getAccoutDetailsByAccountType(token, id);
        dispatch(userSlice.actions.setUserData(accountDetails.data))
    } catch (e) {
        return e
    }
}

export const getLoginHistoryThunk = (token: string, payload: string) => async (dispatch: AppDispatch) => {
    try {
        const accountDetails = await Api.getLoginHistory(token, payload);
        dispatch(userSlice.actions.setLoginHistory(accountDetails.data))
    } catch (e) {
        return e
    }
}

export const sendNewWithdrawalSettings = (token: string, settings: {
    blockWithdrawal: boolean,
    verifyWithdrawal: boolean,
    withdrawalCode: string
}, id: number | null) => async (dispatch: AppDispatch) => {
    try {
        const res = await Api.setNewWithdrawalSettings(token, settings)
        const accountDetails = await Api.getAccoutDetailsByAccountType(token, id);
        dispatch(userSlice.actions.setUserData(accountDetails.data))
        return res
    } catch (e) {
        return e
    }
}

export const sendWithdrawalPinCodeThunk = (token: string, code: string) => async (dispatch: AppDispatch) => {
    return await Api.sendWithdrawalCode(token, code)
}

export const sendVerifyCodeToEmailThunk = (token: string) => async (dispatch: AppDispatch) => {
    return await Api.sendVerifyCodeToEmail(token)
}

export const checkWithdrawalStatusThunk = (token: string) => async (dispatch: AppDispatch) => {
    return await Api.checkWithdrawalStatus(token)
}

export const sendVerifyCodeToEmailForChangeSettingsThunk = (token: string) => async (dispatch: AppDispatch) => {
    return await Api.sendVerifyCodeToEmailForChangeSettings(token)
}

export const verifyOldEmailThunk = (token: string) => async (dispatch: AppDispatch) => {
    return await Api.verifyOldEmail(token)
}

export const checkAvailableEmailThunk = (email: string) => async (dispatch: AppDispatch) => {
    try {
        return await Api.checkAvailableEmail(email)
    } catch (e) {
        return e
    }

}

export const GetLimitsListThunk = (token: string, userNumber: string | null) => async (dispatch: AppDispatch) => {
    try {
        const response = await Api.getLimitsData(token, userNumber)
        dispatch(setAccountsLimit(response.data))
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
    }
}

export const SetNewLimitsThunk = (token: string, data: any) => async (dispatch: AppDispatch) => {
    try {
        return await Api.setNewLimits(token, data)
    } catch (e: any) {
        if (e.response.data === "Invalid authorization data") {
            dispatch(setTokenState(false))
        }
        throw e;
    }
}

export const getAccountConfirmationLetterThunk = (token: string, accountId: number | null, accountNumber: string) => async (dispatch: AppDispatch) => {
    return await Api.getAccountConfirmationLetter(token, accountId, accountNumber)
}

export const getBicCodeThunk = (iban: string) => async (dispatch: AppDispatch) => {
    return await ApiBicChecker.getBicCode(iban)
}

export const GetAccountRequestedLimitsData = (token: string, userId: number | null) => async (dispatch: AppDispatch) => {
    return await Api.getRequestedLimitsData(token, userId)
}
