import React, {Dispatch, SetStateAction} from 'react';
import ToAnLuxPayTransferForm
    from "../../../../../components/ToAnLuxPayTransferForm/ToAnLuxPayTransferForm";


type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    setTransferData: Dispatch<SetStateAction<any>>
    isButtonDisable: boolean | undefined
    setIsButtonDisabled: Dispatch<React.SetStateAction<boolean>>
}

const ToAnLuxPayStepOne = (props: PropsType) => {

    return (
        <ToAnLuxPayTransferForm
            setCurrent={props.setCurrent}
            setTransferData={props.setTransferData}
            isButtonDisable={props.isButtonDisable}
            setIsButtonDisabled={props.setIsButtonDisabled}
        />

    );
};

export default ToAnLuxPayStepOne;
