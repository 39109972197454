import React, {useEffect, useState} from 'react';
import s from './ExchangeRates.module.css'
import {motion} from "framer-motion";
import {Formik, useFormikContext} from "formik";
import MyInput from "../../../../components/ui/MyInput/MyInput";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {
    setCurrencyExchangeAmountCheck,
    setCurrencyExchangeFromCheck, setCurrencyExchangeResultCheck,
    setCurrencyExchangeToCheck, setCurrencyExchangeToAmountCheck
} from "../../../../store/reducers/UserSlice";
import {
    sendCurrencyExchangeAmountCheck,
    sendCurrencyExchangeAmountReverseCheck
} from "../../../../store/reducers/ActionCreators";
import useDebounce from "../../../../hooks/useDebounce";
import * as yup from "yup";
import switcher from '../../../../assets/img/personalAccount/switcher.svg'

const SetCurrencyByAccount = (props: any) => {

    const {values, setFieldValue} = useFormikContext();

    const dispatch = useAppDispatch()

    const currencyExchangeResultCheck = useAppSelector(state => state.userReducer.currencyExchangeResultCheck)

    useEffect(() => {
        // @ts-ignore
        dispatch(setCurrencyExchangeFromCheck(values.sellCurrency))
        // @ts-ignore
    }, [values.sellCurrency])

    useEffect(() => {
        // @ts-ignore
        dispatch(setCurrencyExchangeToCheck(values.buyCurrency))
        // @ts-ignore
    }, [values.buyCurrency])

    useEffect(() => {
        // @ts-ignore
        if (!!values.sellAmount && values?.sellAmount?.length > 0) {
            // @ts-ignore
            dispatch(setCurrencyExchangeFromCheck(values.sellCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeToCheck(values.buyCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeAmountCheck(values.sellAmount))
        }

        // @ts-ignore
        if (!!values.buyAmount && values?.buyAmount?.length > 0) {
            // @ts-ignore
            dispatch(setCurrencyExchangeFromCheck(values.buyCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeToCheck(values.sellCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeToAmountCheck(values.buyAmount))
        }
        // @ts-ignore
    }, [values.buyAmount, values.sellAmount])

    useEffect(() => {
        // @ts-ignore
        if (values.sellAmount === '') {
            dispatch(setCurrencyExchangeResultCheck(''))
            // @ts-ignore
            dispatch(setCurrencyExchangeAmountCheck(''))
        }
        // @ts-ignore
    }, [values.sellAmount])

    useEffect(() => {
        // @ts-ignore
        if (values.buyAmount === '') {            
            dispatch(setCurrencyExchangeResultCheck(''))
            // @ts-ignore
            dispatch(setCurrencyExchangeToAmountCheck(''))
        }
        // @ts-ignore
    }, [values.buyAmount])

    useEffect(() => {
        // @ts-ignore
        if (values.sellAmount !== '') {
            
        }
        // @ts-ignore
    }, [values.sellAmount])


    useEffect(() => {
        // @ts-ignore
        if (values.buyAmount !== '') {
            
        }
        // @ts-ignore
    }, [values.buyAmount])

    return null;
};

const validationSchema = yup.object({
    sellAmount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits"),
    buyAmount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits"),
})

const ExchangeRates = () => {

    const currencyExchange = useAppSelector(state => state.userReducer.currencyExchangeCheck)
    
    const currencyExchangeResultCheck = useAppSelector(state => state.userReducer.currencyExchangeResultCheck)

    const [currencySwaped, setCurrencySwaped] = useState(false)

    const dispatch = useAppDispatch()

    const handleRequest = (debouncedValue: any) => {
        if (debouncedValue.amount !== '') {
            const tempData = {...debouncedValue, amount: currencyExchangeResultCheck && currencySwaped ? currencyExchangeResultCheck : debouncedValue.amount}
            delete tempData.toAmount
            dispatch(sendCurrencyExchangeAmountCheck(tempData))
            return
        }
        if (debouncedValue.toAmount !== '') {
            const tempData = {...debouncedValue, amount: currencyExchangeResultCheck && currencySwaped ? currencyExchangeResultCheck : currencyExchange.toAmount}
            delete tempData.toAmount    
            dispatch(sendCurrencyExchangeAmountReverseCheck(tempData))
            return
        }
    }

    const debouncedValue = useDebounce(currencyExchange, 1500);
    const debouncedCurrencyValue = useDebounce(currencySwaped, 2000);

    useEffect(() => {
        handleRequest(debouncedValue)
    }, [debouncedValue])

    useEffect(() => {
        setCurrencySwaped(false)
    }, [debouncedCurrencyValue])

    enum currencies {
        EUR = 'EUR',
        GBP = 'GBP',
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.desc_block}>
                <p className={s.desc_text}>
                    Below in the currency calculator you can check the exchange rates offered by LuxPay. Provided amounts include &nbsp;
                    <a href="https://bank.luxpay.eu/pricing" className={s.link}>fee</a>.
                </p>
            </div>

            <div className={s.table_wrapper}>
                <Formik
                    initialValues={{
                        sellAmount: '',
                        sellCurrency: currencies.EUR,
                        buyAmount: '',
                        buyCurrency: currencies.GBP,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        setCurrencySwaped(true)
                        
                        // let startSellAmount = values.sellAmount
                        let startSellCurrency = values.sellCurrency                     

                        actions.setFieldValue('sellAmount', "")
                        actions.setFieldValue('buyAmount', "")
                        actions.setFieldValue('sellCurrency', values.buyCurrency)
                        actions.setFieldValue('buyCurrency', startSellCurrency)
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (
                        <form className={s.form} onSubmit={handleSubmit}>
                            <div className={s.form_block}>
                                <div className={s.field_wrapper}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            sell
                                        </div>
                                        <MyInput id='sellAmount'
                                                 name='sellAmount'
                                                 onChange={handleChange}
                                                 value={(currencyExchangeResultCheck && !values.sellAmount) ? currencyExchangeResultCheck : values.sellAmount}
                                                //  disabled={!!values.buyAmount}
                                                 isError={touched.sellAmount && !!errors.sellAmount}
                                        />
                                        {errors.sellAmount && touched.sellAmount &&
                                            <div className={s.error_message}>{errors.sellAmount}</div>}
                                    </div>
                                    <div className={s.sell_currency_block}>
                                        <MyInput id='sellCurrency'
                                                 name='sellCurrency'
                                                 onChange={handleChange}
                                                 disabled={true}
                                                 value={values.sellCurrency}
                                        />
                                    </div>
                                </div>
                                <div className={s.swipe}>
                                    <button className={s.swipe_button} type='submit'>
                                        <img src={switcher} alt="switcher" />
                                    </button>
                                </div>
                                <div className={s.field_wrapper}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            buy
                                        </div>
                                        <MyInput id='buyAmount'
                                                 name='buyAmount'
                                                 onChange={handleChange}
                                                 value={(currencyExchangeResultCheck && !values.buyAmount) ? currencyExchangeResultCheck : values.buyAmount}
                                                //  disabled={!!values.sellAmount}
                                                 isError={touched.buyAmount && !!errors.buyAmount}
                                        />
                                        {errors.buyAmount && touched.buyAmount &&
                                            <div className={s.error_message}>{errors.buyAmount}</div>}
                                    </div>
                                    <div className={s.sell_currency_block}>
                                        <MyInput id='buyCurrency'
                                                 name='buyCurrency'
                                                 onChange={handleChange}
                                                 disabled={true}
                                                 value={values.buyCurrency}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className={s.converted_amount_block}>
                                <span>Amount of currency to sell: </span>
                                <b>{values.sellAmount ? values.sellAmount : currencyExchangeResultCheck} {values.sellCurrency}; </b>
                                <span>buying </span>
                                <b>{values.buyAmount ? values.buyAmount : currencyExchangeResultCheck} {values.buyCurrency}</b>
                            </div>
                            <SetCurrencyByAccount values={values}/>
                        </form>
                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default ExchangeRates;
