import React from 'react';
import s from './DownloadStatement.module.css'
import {Api} from "../../../../../api/Api";
import {useAppSelector} from "../../../../../hooks/redux";

const DownloadStatement = (props: any) => {

    const token = useAppSelector(state => state.userReducer.userToken)
    const accountId = useAppSelector(state => state.userReducer.chosenAccountTypeId)

    const accountInfo = {
        accountNumber: props?.dataForStatement?.hiddenAccountNumber ? props?.dataForStatement?.hiddenAccountNumber : "ALL",
        dateFrom: props?.dataForStatement?.from,
        dateTo: props?.dataForStatement?.to,
        accountId: accountId,
        transferType: "all"
    }

    const getStatement = () => {
        Api.getStatementInfo(token, accountInfo)
            .then((res: any) => {
                const url = process.env.REACT_APP_API_URL + res.data
                window.open(url, '_blank');
            })
            .catch((err: any) => {
                console.log(err)
            })
    }

    return (

        <div className={s.statementWrapper} >
            <div className={s.buttonsWrapper}>
                <p className={s.downloadTitle}>Download as</p>
                <p className={s.buttonTitle} onClick={() => getStatement()}>PDF</p>
                {/* <div className={s.separator}/> */}
                {/*<p className={s.buttonTitle}>XLSX</p>*/}
                {/*<div className={s.separator}/>*/}
                {/* <p className={s.buttonTitle}>XLM</p> */}
                {/*<div className={s.separator}/>*/}
                {/*<p className={s.buttonTitle}>LITAS-ESIS</p>*/}
                {/*<div className={s.separator}/>*/}
                {/*<p className={s.buttonTitle}>FIDAVISTA</p>*/}
            </div>

            {/*<div className={s.printWrapper}>*/}
            {/*    <img src={print} alt="print_icon"/>*/}
            {/*    <p className={s.print}>Print</p>*/}
            {/*</div>*/}
        </div>
    )
}


export default DownloadStatement
